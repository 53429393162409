import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import HeaderIndex from '../components/headerIndex';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';




const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    cardShadow: {
      boxShadow: '5px 5px 20px #e0e0e0',
  }
  }),
);

export default function About() {
  const classes = useStyles();

  return (
    <Grid Container direction="column">
      <Grid item>
        <HeaderIndex title="Thomas Bompaire"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
        <img src="https://www.southside-interactive.com/wp-content/uploads/2021/03/Illus_Socle_600.png" style={{width: "100%", marginTop: "30px"}}/>
        <PageIntro content="Je conçois et déploie des applications utilisées au quotidien en entreprise depuis plus de 10 ans. Ce site présente à l'aide d'éléments concrets ce qui fait de moi un spécialiste pour concevoir des outils numériques, à la croisée de l'IT, des RH et des métiers." />
        <Card className={classes.cardShadow}>
        <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button component={Link} to="/experience-applis-web-mobile">
          <ListItemText primary="Mon expérience des applis web et mobile" />
          <Chip label="81"></Chip>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/expertise-dev-technique">
          <ListItemText primary="Mes expertises dev & technique " />
          <Chip label="58"></Chip>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/conviction-implication">
          <ListItemText primary="Ma conviction du besoin d'impliquer" />
          <Chip label="18"></Chip>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/sensibilite-produit">
          <ListItemText primary="Ma sensibilité produit" />
          <Chip label="11"></Chip>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/ecrire-formuler">
          <ListItemText primary="Formuler et écrire !" />
          <Chip label="51"></Chip>
        </ListItem>
      </List>
    </div>
        </Card>
      </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
